(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.controller:SettingsCtrl
   *
   * @description
   *
   */
  angular
    .module('settings')
    .controller('SettingsCtrl', SettingsCtrl);

  function SettingsCtrl($scope, $location, App) {
    $scope.user = $scope.getItemFromLocalStorage("userDetails");
    $scope.paymentData = $scope.getItemFromLocalStorage("paymentData");

    $scope.removeCreditCard = function() {
      $scope.paymentData = {};
      $scope.setItemInLocalStorage("paymentData", {});
    }

    $scope.saveUserDetails = function() {
      if ($scope.user.Address != undefined && typeof($scope.user.Address) != "string") {
        var place = getDataFromGooglePlaceObject($scope.user.Address);
        $scope.user.Longitude = place.Longitude;
        $scope.user.Latitude = place.Latitude;
        $scope.user.Address = place.Address;
      }
      $scope.setItemInLocalStorage("userDetails", $scope.user);
      App.updateUserDetails($scope.user);
      $location.path("menu");
    }

    $scope.sendPushChanged = function() {
      $scope.setItemInLocalStorage("userDetails", $scope.user);
      App.updateUserDetails($scope.user);
    }

    $scope.logout = function() {
      localStorage.clear();
      $location.path("login");
    }

    var getDataFromGooglePlaceObject = function(place) {
      if (place == undefined || place.geometry == undefined) return {};
      return {
        Longitude: place.geometry.location.lng(),
        Latitude: place.geometry.location.lat(),
        Address: place.formatted_address
      }
    }

  }
}());
